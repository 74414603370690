import React from 'react';
import { Link } from 'gatsby';
import Scroll from './Scroll';

import AngleUpSvg from '../assets/icons/angle-up-solid.svg';

import DiscordIcon from '../assets/icons/discord48.png';
import TwitterIcon from '../assets/icons/twitter48.png';
import FacebookIcon from '../assets/icons/facebook48.png';
import RedditIcon from '../assets/icons/reddit48.png';

class Footer extends React.Component {
  render() {
    return (
      <footer className="bg-dark-gray py-4">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 col-md-10 text-center">
              <div className="small text-center text-white">
                <Scroll type="id" element="home">
                  <a className="nav-link" href="#home">
                    <img
                      alt="Home"
                      src={AngleUpSvg}
                      className="bounce-up-down mb-4"
                      style={{ width: 48, height: 48 }}
                    />
                  </a>
                </Scroll>
              </div>
              <div className="row align-items-center justify-content-center text-center">
                <div>
                  <a
                    href="https://discord.gg/PqyMGYv"
                    target="_blank"
                    title="PixelShard Discord"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="PixelShard Discord"
                      src={DiscordIcon}
                      className="text-white mb-4 mx-2"
                      style={{ width: 48, height: 48 }}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.facebook.com/PixelShardLabs/"
                    target="_blank"
                    title="PixelShard Facebook"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="PixelShard Facebook"
                      src={FacebookIcon}
                      className="text-white mb-4 mx-2"
                      style={{ width: 48, height: 48 }}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://twitter.com/pixelshardlabs"
                    target="_blank"
                    title="PixelShard Twitter"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="PixelShard Twitter"
                      src={TwitterIcon}
                      className="text-white mb-4 mx-2"
                      style={{ width: 48, height: 48 }}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.reddit.com/r/ActionSquadGame/"
                    target="_blank"
                    title="PixelShard Twitter"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="PixelShard Reddit"
                      src={RedditIcon}
                      className="text-white mb-4 mx-2"
                      style={{ width: 48, height: 48 }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <hr className="divider light my-4" />
          <div className="row align-items-center justify-content-center">
            <div className="text-center col-12">
              <Link className="nav-link footer-link" to="/terms">
                TERMS OF SERVICE
              </Link>
            </div>
            <div className="text-center col-12">
              <Link className="nav-link footer-link" to="/privacy">
                PRIVACY POLICY
              </Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
